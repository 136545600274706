export default {
  data: () => ({
    list: [],
    page: {
      page: 1,
      size: 10,
      total: 0
    },
    sizeList: [10, 20, 50, 100],
    pageLayout: 'total,sizes,prev, pager, next',
    loading: false
  }),
  methods: {
    onSizeChange(val) {
      this.page.size = val
      this.page.page = 1
      this.getData()
    },
    onPageChange(val) {
      this.page.page = val
      this.getData()
    },
    handleResetPage() {
      this.page.page = 1
      this.page.size = 10
    },
    getList() {
      this.$log('please add getList method first')
    },
    getData() {
      this.loading = true
      this.getList()
        .then(res => {
          this.list = res.list
          this.page.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    init() {
      this.handleReset()
      this.handleResetPage()
      this.getData()
    },
    handleReset() {},
    handleSearch() {
      this.handleResetPage()
      this.getData()
    }
  },
  mounted() {
    this.$log.mixin('table', {
      'data.page': { ...this.page },
      'data.loading': this.loading,
      methods: ['onSizeChange', 'onPageChange', 'handleResetPage', 'handleReset']
    })
    this.getData()
  }
}
